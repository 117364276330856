import React,{useState} from 'react';
// import { Redirect } from 'react-router-dom';
import { useHistory  } from 'react-router-dom';

import {productImageUrl,apiUrl,publicConfig} from '../../Api/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const axios = require('axios');

function ProductPurchaseForm({product,supplier,showCheckout,setShowCheckout,supplierID}) {
  let history = useHistory();
  
  // console.log('supplierID ')
  // console.log(supplierID)
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(product.price);
  const [totalPrice, setTotalPrice] = useState(product.price*1);

  const [paymentMethod, setPaymentMethod] = useState('Cash on Delivery');
  const [showTransactionDiv, setShowTransactionDiv] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [checkoutClicked, setCheckoutClicked] = useState(false);
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);
  
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [senderAccountNumber, setSenderAccountNumber] = useState('');
  const [transectionID, setTransectionID] = useState('');
  
  const PaymentTypeOnChange = (e) => {
    setPaymentMethod(e.target.value)
    if(e.target.value!=='Cash on Delivery'){setShowTransactionDiv(true)}
    else{setShowTransactionDiv(false)}    
  }
  const increaseQuantity = () => {
    var tempQualtity=quantity+1;
    setQuantity(tempQualtity)
    setTotalPrice(tempQualtity*price)
  }

  const decreaseQuantity = () => {
    if(quantity===1){return;}
    var tempQualtity=quantity-1;
    setQuantity(tempQualtity)
    setTotalPrice(tempQualtity*price)
  }

  const submitData = async (e) => {
    e.preventDefault();
    
    
    

    if (name.length<1){toast.error("Please insert your name");return;};
    if (email.length<1){toast.error("Please insert your email address");return;}
    if (email.length>0 && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(String(email).toLowerCase()))){toast.error("Email is not valid, Please insert a valid Email");return;}
    if (mobile.length<1){toast.error("Please insert your Phone Number");return;};        
    if (address.length<1){toast.error("Please insert your Address");return;};
    if (showTransactionDiv){
        if (senderAccountNumber.length<1 ){toast.error("Please insert Sender Account Number");return;};
        if (transectionID.length<1){toast.error("Please insert Transection ID");return;};
    }

    setCheckoutClicked(true);

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("name", name);
      formData.append("phone", mobile);
      formData.append("address", address);
      formData.append("add_require", additionalInformation);
      formData.append("payment", paymentMethod);
      formData.append("send_account_number", senderAccountNumber);
      formData.append("transactionid", transectionID);
      formData.append("product_name", product.product_name);
      formData.append("product_id", product.id);
      formData.append("supplier_id", supplierID);
      formData.append("final_quantity", quantity);
      formData.append("final_checkout_price", totalPrice);
      formData.append("confirm_total_price", totalPrice);

      const res = await publicConfig().post('/product/order', formData); 
      // console.log(res); 
      if(res.data.success){setCheckoutClicked(false);toast.success("Successfully Placed Your Order");history.push('/success');}      
    } catch (err) {
      console.log(err);  
    } 
  }

    return (
        <>
        {/* <Redirect to="/success" /> */}
        
        <div class='container' style={showCheckout ? {display:'block'} : {display:'none'}}>
        <div class="mt-4 ">
            <a href="#" className='btn btn-primary' onClick={()=>{setShowCheckout(false)}}>Go Back</a>
        </div>
          <div class = 'productShow'>
            <div class='row'>
              <div class='col-6'>
                <div class="row">
                  <div class="col-4">
                    <div class="productImageDiv" style={{ backgroundImage: `url("${productImageUrl+product.product_image.product_multiple_photo_name}")` }}  ></div>
                  </div>
                  <div className="col-8 productdetails">
                    <h6>{product.product_name}</h6>
                    <p>Price : Tk.{price}</p>
                    <div class="quantitydiv">
                      <div class="buttons" onClick={decreaseQuantity}>-</div>
                      <p class="quantity">{quantity}</p>
                      <div class="buttons" onClick={increaseQuantity}>+</div>
                    </div>
                    <p class="totalPrice">Total :<span>Tk.{totalPrice}</span></p>
                  </div>
                </div>
              </div>
              <div class='col-6 deliveryCharge'>
                <div>
                  <h6>Delivery Charge</h6>
                <p>Inside Dhaka: 50tk</p>
                <p>Outside Dhaka: 150tk</p>
                </div>
              </div>
            </div>
          </div>
       
        {errorMessage && (
          <div class="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <h4 class="section-title-inner mb-4"><span><i class="glyphicon glyphicon-shopping-cart"></i> Checkout</span></h4>
          <form>
              <div class='row'>
                <div class="col-6">
                  <div class="form-group">
                    <label class='text-dark'>Full Name</label>
                    <input type="text" class="form-control" placeholder="Enter Full Name" value={name} onChange={(e)=>setName(e.target.value)}/>                 
                  </div>
                  <div class="form-group">
                    <label class='text-dark'>Email address</label>
                    <input type="email" class="form-control" placeholder="Enter Email address" value={email} onChange={(e)=>setEmail(e.target.value)} />                  
                  </div>
                  <div class="form-group">
                    <label class='text-dark'>Phone Number</label>
                    <input type="text" class="form-control" placeholder="Enter Phone Number" value={mobile} onChange={(e)=>setMobile(e.target.value)}/>                 
                  </div>
                  <div class="form-group">
                    <label class='text-dark'>Address</label>
                    <textarea rows="3" placeholder="Your Full Address" class="form-control"  onChange={(e)=>setAddress(e.target.value)}>{address}</textarea>            
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class='text-dark'>Additional information</label>
                    <textarea rows="10" placeholder="Any Other Instruction ?" class="form-control"  onChange={(e)=>setAdditionalInformation(e.target.value)}>{additionalInformation}</textarea>            
                  </div>

                  <div class="form-group">
                    <label class='text-dark'>Payment Type</label>
                    <select class="form-control" onChange={PaymentTypeOnChange}>
                      <option disabled="disabled" hidden="hidden" readonly="readonly" selected="selected" value="">Choose Payment Method...</option>
                      <option selected="selected" value="Cash on Delivery">Cash on Delivery</option>
                      <option value="Bkash"> Bkash - 01817205106 </option>
                      <option value="Rocket"> Rocket - 018172051062 </option>
                      <option value="Nagad"> Nagad - 01817205106 </option>
                    </select>           
                  </div>

                  {showTransactionDiv && (
                    <div>
                      <div class="form-group">
                        <label class='text-dark'>Sender Account Number</label>
                        <input type="text" class="form-control" placeholder="Enter Sender Account Number" value={senderAccountNumber} onChange={(e)=>setSenderAccountNumber(e.target.value)}/>                 
                      </div>

                      <div class="form-group">
                        <label class='text-dark'>Transection ID</label>
                        <input type="text" class="form-control" placeholder="Enter Transection ID" value={transectionID} onChange={(e)=>setTransectionID(e.target.value)} />                 
                      </div>
                    </div>
                  )}
                  
                  
                </div>
              </div>
              {checkoutClicked ? <button class="btn btn-secondary w-100 mb-5 mt-2" onClick={(e)=>e.preventDefault()}>Payment Processing... Please Wait</button> 
                :<button class="btn btn-primary w-100 mb-5 mt-2" onClick={submitData}>Submit</button> }
                <ToastContainer />
          </form>
        </div>
                
        </>
    )
}

export default ProductPurchaseForm
