import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Product from '../Components/Homepage/Product'
import {domainUrl,apiUrl} from '../Api/api'
import {PageLoader} from '../Resources/loader'
const axios = require('axios');

function AllOurProducts() {
    const [products, setProducts] = useState(false);

    const getallProducts = async () => {
        try {
          const res = await axios.get(apiUrl+'/supplier/products');
          var productsArray = [];
          res.data.forEach(data => {
              if(data.domain_url === domainUrl){
                productsArray.push(data); 
              }
          });
          setProducts(productsArray);       
          
        } catch (err) {
          console.log(err);
        }
    };

    useEffect(() => {    
        getallProducts();      
    }, []);

    if(products===false){return PageLoader()}
    return (
        <>
            <section id="all-product" class="py-5">
                <div class="container">
                    <div class="titleDiv">
                        <div class="title"><h2>Our All Products</h2></div>                        
                    </div>
                    <div class="br"></div>
                    <div class="row pt-4 pt-md-5">
                    {products && products.map((item,index) => {                                              
                    return (                        
                        <Product  key={index} product={item} ></Product>
                    );
                    })}                       
                    </div>                    
                </div>
            </section>
            
        </>
    )
}

export default AllOurProducts
