import React, { useState}  from 'react'
import { Link } from 'react-router-dom'
import CategoryProduct from './CategoryProduct'
import {PageLoader} from '../../Resources/loader'

function Categories({categories,supplier,products}) {
    var allCategoriesArray = [];
    if(categories && supplier){        
        categories.forEach(category => {
            if(category.added_by === supplier.user_id){
                allCategoriesArray.push(category);
            }
        });        
    }
    
    if(products===false){return PageLoader()}
    return(
        <>
            {allCategoriesArray.length>0 && allCategoriesArray.map((item,index) => { 
            return (
                <div key={index}>
                <section id="all-product" class="py-5">
                <div class="container">                    
                    <div class="titleDiv">
                        <div class="title"><h2>{item.category_name}</h2></div>
                        <Link className="view-all" to={'/category/'+item.id}>View All</Link>
                    </div>                    
                    <div class="br"></div>
                    <div class="row pt-4 pt-md-5">                
                        <CategoryProduct category={item} products={products}></CategoryProduct>                
                    </div>
                </div>
                </section>
                
                </div>
            )
            })}
        </>
    );
}

export default Categories
