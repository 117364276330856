import React, { useState, useEffect } from 'react';
import {domainUrl,apiUrl} from '../Api/api'
import ProductShowDetails from '../Components/ProductViewPage/ProductShowDetails';
import ProductPurchaseForm from '../Components/ProductViewPage/ProductPurchaseForm';
import {PageLoader} from '../Resources/loader'
const axios = require('axios');

function ProductDetails({match}) {
    const [product, setProduct] = useState(false);
    const [supplier, setSupplier] = useState(false);
    const [supplierID, setSupplierID] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);

    

    const getProduct = async () => {
        try {
          const res = await axios.get(apiUrl+'/supplier/product/'+match.params.slug);
          //console.log(res.data)
          setProduct(res.data)
        } catch (err) {
          console.log(err);
        }
      };

      const getSupplierID = async ($userid) => {
        try {
          const res = await axios.get(apiUrl+'/supplier/'+$userid);          
          setSupplierID(res.data.id)
        } catch (err) {
          console.log(err);
        }
      };

      const getSupplier = async () => {
        try {
            const res = await axios.get(apiUrl+'/supplier');          
            res.data.forEach(data => {
                if(data.user_domain === domainUrl){
                  setSupplier(data)  
                  getSupplierID(data.user_id)                 
                }
            });           
          } catch (err) {
            console.log(err);
          }
      }
    
    useEffect(() => { 
        setProduct(false)   
        setSupplier(false) 
          
        getProduct()  
        getSupplier();
        window.scrollTo(0, 0);      
    }, [match.params.slug]);
    
    return (
        <>
           {(product&&supplier) ? (
              <>
                <ProductShowDetails product={product} setShowCheckout={setShowCheckout} showCheckout={showCheckout}/>
                <ProductPurchaseForm product={product} supplier={supplier} setShowCheckout={setShowCheckout} showCheckout={showCheckout} supplierID={supplierID}></ProductPurchaseForm>                                     
              </>
            ) : PageLoader() }           
        </>
    )
}

export default ProductDetails
