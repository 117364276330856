import React, { useState, useEffect } from 'react';
import Slider from '../Components/Homepage/Slider'
import AllOurProducts from '../Components/Homepage/AllOurProducts'
import Categories from '../Components/Homepage/Categories'
import ExclusiveProducts from '../Components/Homepage/ExclusiveProducts'
// import Slider from '../Components/Homepage/Slider'
import {domainUrl,apiUrl} from '../Api/api'
import {PageLoader,CircleLoader} from '../Resources/loader'
const axios = require('axios');


function HomePage() {

  const [allproducts, setAllProducts] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [allCategories, setAllCategories] = useState(false);
  const [sliders, setSliders] = useState(false);

    const getallProducts = async () => {
        try {
          const res = await axios.get(apiUrl+'/supplier/products');
          var productsArray = [];
          res.data.forEach(data => {
              if(data.domain_url === domainUrl){
                productsArray.push(data); 
              }
          });
          setAllProducts(productsArray);       
          
        } catch (err) {
          console.log(err);
        }
      };

      const getallCategory = async () => {
        try {
            const res = await axios.get(apiUrl+'/category/product');          
            setAllCategories(res.data)           
        } catch (err) {
            console.log(err);
          }
      };

      const getSupplier = async () => {
        try {
            const res = await axios.get(apiUrl+'/supplier');          
            res.data.forEach(data => {
                if(data.user_domain === domainUrl){
                  setSupplier(data)                   
                }
            });           
          } catch (err) {
            console.log(err);
          }
      }

      const getSliders = async () => {
        try {
          const res = await axios.get(apiUrl+'/supplier/slider');
          var slidersArray = [];
          res.data.forEach(data => {
              if(data.domain_url === domainUrl){
                slidersArray.push(data); 
              }
          });
          setSliders(slidersArray);       
          
        } catch (err) {
          console.log(err);
        }
      };

    useEffect(() => {    
    getallProducts();        
    getallCategory();        
    getSupplier();   
    getSliders();     
    }, []);

    
    var isloading = true;

    return (
        // <div className='loaderParent'>            
        //              
        // </div>
        <>
        {isloading ? (   
             <>
             <Slider sliders={sliders}></Slider>
             <ExclusiveProducts products={allproducts}></ExclusiveProducts>
             <AllOurProducts products={allproducts}></AllOurProducts>
             <Categories categories ={allCategories} supplier={supplier} products={allproducts}></Categories> 
             </>               
        ) : (        
            PageLoader()
        )}
        </>

        
    )
}

export default HomePage
