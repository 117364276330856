import React from 'react'
import Product from './Product'
import {PageLoader} from '../../Resources/loader'
import { Link } from 'react-router-dom'

function AllOurProducts({products}) {

    if(products===false){return PageLoader()}
    return (
        <>
            <section id="all-product" class="py-5">
                <div class="container">
                    <div class="titleDiv">
                        <div class="title"><h2>Our All Products</h2></div>
                        <Link to="/all-products" className="view-all">View All</Link>
                    </div>
                    <div class="br"></div>
                    <div class="row pt-4 pt-md-5">
                    {products && products.map((item,index) => { 
                    if(index>7){return}                           
                    return (                        
                        <Product  key={index} product={item} ></Product>
                    );
                    })}                       
                    </div>                    
                </div>
            </section>
            
        </>
    )
}

export default AllOurProducts
