import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import {domainUrl,apiUrl} from '../../Api/api'
const axios = require('axios');

function Header({logo}) {
    const [allCategories, setAllCategories] = useState(false);
    const [supplier, setSupplier] = useState(false);    

    const getallCategory = async () => {
        try {
            const res = await axios.get(apiUrl+'/category/product');          
            setAllCategories(res.data)           
        } catch (err) {
            console.log(err);
          }
      };

      const getSupplier = async () => {
        try {
            const res = await axios.get(apiUrl+'/supplier');          
            res.data.forEach(data => {
                if(data.user_domain === domainUrl){
                  setSupplier(data)                   
                }
            });           
          } catch (err) {
            console.log(err);
          }
      }
      
      var allCategoriesArray = [];        
      if(supplier&&allCategories){               
        allCategories.forEach(category => {
            if(category.added_by === supplier.user_id){
                allCategoriesArray.push(category);
            }            
        });  
        console.log(allCategoriesArray)    
      }

    useEffect(() => {  
    getallCategory();  
    getSupplier();    
    }, []);
    return (
        <div>
            <section id="botomnav-dark" className="sticky-top">
    
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark pt-3">
            <div className="container-fluid container">
                <Link className="navbar-brand" to="/">{logo && <img className="img-fluid" src={"http://dhakatradeinfo.com/uploads/supplier/logo/"+logo} alt="logo" />}</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mb-2 mb-lg-0 ml-auto">
                        <li className="nav-item">
                        <Link className="nav-link active"  to="/"><span></span>HOME</Link>
                        </li>
                        <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" to="/product" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><span></span>
                        PRODUCT
                        </Link>

                        <ul className="dropdown-menu meghamenu" aria-labelledby="navbarDropdown">
                        {allCategoriesArray.length>0 && allCategoriesArray.map((item,index) => { 
                            return (
                                <li key={index}><Link className="dropdown-item" to={'/category/'+item.id}>{item.category_name}</Link></li>
                            )
                        })}                           
                            
                        </ul>

                        </li>
                        
                        <li className="nav-item">
                        <Link className="nav-link" to="/blog"><span></span>BLOG</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="/about"><span></span>ABOUT US</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="/contact"><span></span>CONTACT</Link>
                        </li>
                    </ul>
                </div>
            </div>
            </nav>
    
 </section>
        </div>
    )
}

export default Header
