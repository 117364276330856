import React, { useState } from 'react';
import {supplierEmailAddress,publicConfig} from '../Api/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory  } from 'react-router-dom';

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [toEmail, setToEmail] = useState(supplierEmailAddress);
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');

    const [checkoutClicked, setCheckoutClicked] = useState(false);


    const submitData = async (e) => {
        e.preventDefault();
        
        if (name.length<1){toast.error("Please insert your name");return;};
        if (email.length<1){toast.error("Please insert your email address");return;}
        if (email.length>0 && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(String(email).toLowerCase()))){toast.error("Email is not valid, Please insert a valid Email");return;}
        if (mobile.length<1){toast.error("Please insert your Phone Number");return;};        
        if (message.length<1){toast.error("Please insert your Message");return;};
        
        setCheckoutClicked(true);    
        try {
          const formData = new FormData();
          formData.append("name", name);
          formData.append("toEmail", toEmail);
          formData.append("fromEmail", email);
          formData.append("phone", mobile);
          formData.append("message", message);
    
          const res = await publicConfig().post('/send/contact', formData); 
        //   console.log(res); 
          if(res.data.success){setCheckoutClicked(false);toast.success(res.data.message);}      
        } catch (err) {
          console.log(err);  
        } 
      }


    return (
        <>
            <section id="contact" class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12 my-3">
                            <h3>Contact With Us</h3>
                        </div>
                    </div>
                    <div class="row pt-4 pt-md-5">
                        <div class="col-md-6">
                            <form>
                            <div class="form-group">
                                <label for="name">Name</label>
                                <input type="text" class="form-control" placeholder="Enter Name" value={name} onChange={(e)=>setName(e.target.value)}/>
                            </div>
                            <div class="form-group">
                                <label for="email">Email address</label>
                                <input type="email" name="fromEmail" class="form-control"  placeholder="Enter email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                                <input type="hidden" name="toEmail" class="form-control"  placeholder="Enter email" value="doinikhatbd@gmail.com" />
                            </div>
                            <div class="form-group">
                                <label for="mobile">Mobile No.</label>
                                <input type="text" name="phone" class="form-control" placeholder="Mobile No." value={mobile} onChange={(e)=>setMobile(e.target.value)}/>
                            </div>
                            <div class="form-group">
                                <label for="message">Message</label>
                                <textarea name="message" id="message" rows="5" onChange={(e)=>setMessage(e.target.value)}>{message}</textarea>
                            </div>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="terms" />
                                <label class="form-check-label" for="terms">I know all terms &amp; conditons</label>
                            </div>
                            {checkoutClicked ? <button class="btn btn-secondary w-100 mb-5 mt-2" onClick={(e)=>e.preventDefault()}>Message Sending... Please Wait</button> 
                            :<button class="btn btn-primary w-100 mb-5 mt-2" onClick={submitData}>Send Message</button> }
                            <ToastContainer />

                            </form>
                        </div>
                        <div class="col-md-6">
                            <div class="contact-address">
                            <h5 class="text-dark mt-4">Address</h5>
                            <div class="address">
                                <i class="fas fa-map-marker-alt mr-2"></i>
                                <span>{process.env.REACT_APP_ADDRESS}</span>
                            </div>
                            <h5 class="text-dark mt-4">Contact</h5>
                            <div class="footer_address">
                                <i class="fas fa-phone mr-2"></i>
                                <span>{process.env.REACT_APP_MOBILE_NUMBER}</span>
                            </div>
                            <h5 class="text-dark mt-4">Email</h5>
                            <div class="footer_address">
                                <i class="fas fa-envelope mr-2"></i>
                                <span>{process.env.REACT_APP_EMAIL}</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
        </>
    )
}

export default Contact
