import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {sliderImageUrl} from '../../Api/api';
import {PageLoader} from '../../Resources/loader'

function Slider({sliders}) {    
    
    if(sliders===false){return PageLoader()}
    return (
        <>
            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false} stopOnHover={false}>
                {sliders && sliders.map((slider,index) => {                             
                return (
                    <div>
                        <img src={sliderImageUrl+slider.image} />                    
                    </div> 
                );
                })}                               
            </Carousel>         
        </>
    )
}

export default Slider
