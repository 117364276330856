import React from 'react'
import {productImageUrl} from '../../Api/api'
import { Link } from 'react-router-dom'


function Product({product}) {
    //console.log(product)
    return (
        <>
           <div class="col-md-3 my-3">
                <div class="product-card">
                    <div class="product-top">
                        <div class="product-image">
                            <img src={productImageUrl+product.product_image.product_multiple_photo_name} alt="Product" />
                        </div>
                        <div class="product-link">
                            <Link to={"/product/details/"+product.slug}>View Details</Link>
                        </div>
                        <div class="wishlist">
                        </div>
                    </div>
                    <div class="product-bottom">
                        <h6>{product.product_name.length > 20 ? `${product.product_name.substring(0, 20)}...` : product.product_name}</h6>
                        <p>Price: &#2547; {product.price}/${product.price_dollar}</p>
                    </div>
                </div>
            </div> 
        </>
    )
}

export default Product
