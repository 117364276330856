import React from 'react'
import {productImageUrl,apiUrl} from '../../Api/api'
import CategoryDetails from './CategoryDetails'

function ProductShowDetails({product,setShowCheckout,showCheckout}) {
    //console.log(product);
    return (
        <>
            <section id="product-details" class="py-5 " style={showCheckout ? {display:'none'} : {display:'block'}}>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 col-md-5 col-sm-12">
                                <div class="product-image">
                                    <img src={productImageUrl+product.product_image.product_multiple_photo_name} class="img-fluid" alt="Product Image" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="product-desc">               
                                    <h3>{product.product_name}</h3>
                                    <h5>Price: &#2547;{product.price}</h5>   
                                    
                                    <div class="details">
                                        <p>Brand Name : {product.brand_name}</p>
                                        <span className="underlineBottom"></span>
                                        <p>Model Name/Number : {product.product_code}</p>
                                        <span className="underlineBottom"></span>
                                        {/* <p>Product Type : aaaaaa</p>
                                        <span className="underlineBottom"></span> */}
                                        <p>Color : {product.color}</p>
                                        <span className="underlineBottom"></span>
                                    </div>
                                                                                                       
                                    <div class="mt-4 mb-4">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>{setShowCheckout(true)}}>Buy Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                <div className='product_delivery'>
                                    <h5>Delivery Charge</h5>                                        
                                    <p>Inside Dhaka: 50tk</p>
                                    <p>Outside Dhaka: 150tk</p>

                                    <h5 style={{ marginTop:20 }}>Return & Warranty:</h5>                                        
                                    <p>7 Days Returns</p>
                                    <p>Change of mind is not applicable</p>
                                </div>
                            </div>                            
                        </div>

                        {product.description!=null &&
                            <div className='product_details'>
                                <h5>Product Details</h5>                                        
                                <p dangerouslySetInnerHTML={{ __html: product.description }} />
                            </div>
                        }
                    </div>
                </section>
                <div style={showCheckout ? {display:'none'} : {display:'block'}} >
                    <CategoryDetails categoryID={product.category_id} productID={product.id}></CategoryDetails>
                </div>
                
        </>
    )
}

export default ProductShowDetails
