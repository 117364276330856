import React from 'react'

function Success() {
    return (
        <div class="cardBody">
            <div class="card">
                <div className="success-card-inner">
                <i class="checkmark">✓</i>
                </div>
                <h1>Success</h1>
                <p>We received your purchase request;<br/> we'll be in touch shortly!</p>
            </div>
        </div>
    )
}

export default Success
