import React from 'react'
import {productImageUrl} from '../../Api/api'
import { Link } from 'react-router-dom'

function CategoryProduct ({category,products}) {
    var productsArray = [];
    
    if(category && products){        
        products.forEach(product => {
            if(parseInt(category.id)===parseInt(product.category_id)){
                productsArray.push(product);
            }
        }); 
          
    }

    return (
        <>  
        {productsArray.length>0 && productsArray.map((item,index) => { 
            if(index>7){return}            
            return (
                <div class="col-md-3 my-3" key={index}>
                <div class="product-card">
                    <div class="product-top">
                        <div class="product-image">
                            <img src={productImageUrl+item.product_image.product_multiple_photo_name} alt="Product" />
                        </div>
                        <div class="product-link">
                            <Link to={"/product/details/"+item.slug}>View Details</Link>
                        </div>
                        <div class="wishlist">
                        </div>
                    </div>
                    <div class="product-bottom">
                        <h6>{item.product_name.length > 20 ? `${item.product_name.substring(0, 20)}...` : item.product_name}</h6>
                        <p>Price: &#2547; {item.price} / ${item.price_dollar}</p>
                    </div>
                </div>
            </div>
            )
        })}
        </>
    )
}

export default CategoryProduct
