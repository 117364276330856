import React, { useState } from 'react';
import ExclusiveProduct from './ExclusiveProduct'
import {PageLoader} from '../../Resources/loader'

function ExclusiveProducts({products}) {

    if(products===false){return PageLoader()}
    return (
        <>
           <section id="exclusive-products" class="pb-md-3 bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="product-header">
                        <h3 class="">Exclusive Products</h3>
                        </div>
                    </div>
                </div>
                <div class="br"></div>
                <div class="row pt-4 pt-md-5">
                    <div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slider="autoplay: true; autoplay-interval: 3000">
                        <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-4@m">
                            {products && products.map((item,index) => {                             
                            return (
                                <ExclusiveProduct key={index} product={item}></ExclusiveProduct>
                            );
                            })}
                        
                        </ul>
                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
                    </div>
                </div>
            </div>
            </section>
            
        </>
    )
}

export default ExclusiveProducts
