import React from 'react'
import { Link } from 'react-router-dom'
import {productImageUrl} from '../../Api/api'

function ExclusiveProduct({product}) {
    //console.log(product)
    return (
        <>
        <li>                            
            <div className="box">
                <div className="slide-img">
                    <img src={productImageUrl+product.product_image.product_multiple_photo_name} alt="1" />
                    <div className="overlay">                        
                        <Link to={"/product/details/"+product.slug} className="add-cart">View Details</Link>
                    </div>
                </div>
                <div className="detail-box">
                    <div className="title">
                        <a href=""><h5>{product.product_name.length > 20 ? `${product.product_name.substring(0, 20)}...` : product.product_name}</h5></a>
                    </div>
                    <div className="pricing">
                        <p>Price: &#2547; {product.price}/${product.price_dollar}</p>
                    </div>
                    <div className="addwish">
                        <a href="" title="Add to Wishlist"><i className="fas fa-heart"></i></a>
                    </div>
                </div>
            </div>
        </li>    
        </>
    )
}

export default ExclusiveProduct
