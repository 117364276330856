import React, { useState, useEffect } from 'react';
import {domainUrl,apiUrl} from '../../Api/api'
import { PageLoader } from '../../Resources/loader';
import CategoryProduct from '../Products/CategoryProduct';
const axios = require('axios');


function CategoryDetails({categoryID,productID}) {
    const [allproducts, setAllProducts] = useState(false);
    const [haveProducts, setHaveProducts] = useState(false);
    const [categoryName, setCategoryName] = useState(false);
    
    const getProductsByCategory = async () => {
        try {
          const res = await axios.get(apiUrl+'/category/'+categoryID);          
          setAllProducts(res.data);    
          if(res.data.length>0){setHaveProducts(true);setCategoryName(res.data[0].connect_category.category_name)}  
        } catch (err) {
          console.log(err);
        }
    };

    useEffect(() => {    
        getProductsByCategory(); 
    }, [categoryID]);

    
    return (
        <>  
           {allproducts ? (
           
           <section id="all-product" class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h3>Simillar Products</h3>
                        </div>
                    </div>
                    <div class="br"></div>
                    <div class="row pt-4 pt-md-5">  
                    {allproducts.length!==1 ? (allproducts.map((item,index) => {
                    if(productID===item.id){return;} 
                    return (
                        <CategoryProduct item={item} key={index}></CategoryProduct>
                    )
                    })) : <h4>There are no Products...</h4>}              
                                        
                    </div>
                </div>
            </section>
           
           ) 
           : (PageLoader()) } 
        </>
    )
}

export default CategoryDetails
