import ContentLoader, { Facebook,List } from 'react-content-loader'

const PageLoader = () => (
    <>
    <div className= 'container'>  
        <div className='row mt-5 mb-5'>
            <div className='col-4'><List backgroundColor={'#d3d1d1'} foregroundColor={'#999'}/></div>
            <div className='col-4'><List backgroundColor={'#d3d1d1'} foregroundColor={'#999'}/></div>
            <div className='col-4'><List backgroundColor={'#d3d1d1'} foregroundColor={'#999'}/></div>          
        </div>
        <div className='row mt-5 mb-5'>
            <div className='col-4'><List backgroundColor={'#d3d1d1'} foregroundColor={'#999'}/></div>
            <div className='col-4'><List backgroundColor={'#d3d1d1'} foregroundColor={'#999'}/></div>
            <div className='col-4'><List backgroundColor={'#d3d1d1'} foregroundColor={'#999'}/></div>          
        </div>        
    </div>
    </>
  )

  const CircleLoader = () => (
    <>
    <div className='loaderParent'>
        <div class="loader"></div>
    </div>
    </>
  )

export  {PageLoader,CircleLoader}


