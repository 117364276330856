import React, { useState, useEffect } from 'react';
import {domainUrl,apiUrl} from '../../Api/api'
const axios = require('axios');

function Footer({logo}) {
    const [footer, setfooter] = useState(false);    

    const getFooter = async () => {
        //footer contents
        try {
        const res = await axios.get(apiUrl+'/supplier/footer');                 
        res.data.forEach(data => {
            if(data.domain_url === domainUrl){
                setfooter(data) 
            }
        });           
        } catch (err) {
        console.log(err);
        }        
    }


    useEffect(() => {    
        getFooter();      
    }, []);

    return (
        <>
        {footer && 
        <>
            <section id="footer-two" class="pt-5 pb-4">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3 mt-4 mt-md-0">
                            <div class="logo">
                            {logo && <a href="#"><img class="img-fluid" src={"http://dhakatradeinfo.com/uploads/supplier/logo/"+logo} alt="logo" /></a>}
                            </div>
                        </div>
                        <div class="col-md-3 mt-4 mt-md-0">
                            <h5>Address</h5>
                            <div class="footer_address">
                            <i class="fas fa-map-marker-alt mr-2"></i>
                            <span>{footer.address}</span>
                            </div>
                        </div>
                        <div class="col-md-3 mt-4 mt-md-0">
                            <h5>Contact</h5>
                            <div class="footer_address">
                            <i class="fas fa-phone mr-2"></i>
                            <span>{footer.phone}</span>
                            </div>
                        </div>
                        <div class="col-md-3 mt-4 mt-md-0">
                            <h5>Email</h5>
                            <div class="footer_address">
                            <i class="fas fa-envelope mr-2"></i>
                            <span>{footer.email}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                <section id="footer-buttom" class="bg-dark pt-3">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <p class="text-light">© 2021 All Rights Reserved {process.env.REACT_APP_APP_NAME} Developed by <a class="text-white" href="https://www.uttarainfotech.com/">Uttara</a> <a class="text-white" href="https://uit.com.bd/">Infotech</a></p>
                        </div>
                    </div>
                </div>
                </section>
        </>
        }
        </>
    )
}

export default Footer
