const axios = require('axios');

//Client Side (change this according to client side website)
var domainUrl = 'https://ladiesmarketbd.com/'  
var productImageUrl='http://dhakatradeinfo.com/uploads/product/product_details/';
var sliderImageUrl = 'https://dhakatradeinfo.com/public/uploads/supplier/slider/';

var config  = ""
var supplierEmailAddress = "ladiesmarketbd@gmail.com"

//Api Informations (Main Server Information)
var apiUrl = 'http://www.dhakatradeinfo.com/api'

function publicConfig () {
    config  = axios.create({
        baseURL: apiUrl,
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + tokenValue
        }
    })
    return config 
}

export  {domainUrl,apiUrl,productImageUrl,publicConfig,supplierEmailAddress,sliderImageUrl}