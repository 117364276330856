import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Header from './Components/Header/Header'
import HomePage from './Pages/HomePage'
import Product from './Pages/Product'
import About from './Pages/About'
import Blog from './Pages/Blog'
import Contact from './Pages/Contact'
import ProductDetails from './Pages/ProductDetails';
import Success from './Pages/Success';
import Footer from './Components/Footer/Footer'
import {domainUrl,apiUrl} from './Api/api'
import CategoryDetails from './Pages/CategoryDetails';
import AllOurProducts from './Pages/AllOurProducts';
const axios = require('axios');


function App() {
  const [logo, setlogo] = useState(false);

  const getFooter = async () => {
    try {
    const logoData = await axios.get(apiUrl+'/supplier/logo');                 
    logoData.data.forEach(data => {
        if(data.domain_url === domainUrl){setlogo(data.image)}
    });           
    } catch (err) {
      console.log(err);
    }
  }


useEffect(() => {    
    getFooter();      
}, []);

  return (
    <div>
      <Router>
        <Header logo={logo}></Header>        
        <Switch>
        
          <Route path="/" exact component={HomePage}/>
          <Route path="/product" exact component={Product}/>
          <Route path="/about" exact component={About}/>
          <Route path="/blog" exact component={Blog}/>
          <Route path="/contact" exact component={Contact}/>

          <Route path="/success" exact component={Success}/>

          <Route path="/product/details/:slug" exact component={ProductDetails} />
          <Route path="/category/:id" exact component={CategoryDetails} />
          <Route path="/all-products" exact component={AllOurProducts} />

        </Switch>
        <Footer  logo={logo} ></Footer>
        
      </Router>
    </div>
  )
}

export default App
